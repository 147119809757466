/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Box,
  Grid,
  Hidden,
  withStyles,
} from '@material-ui/core';
import { Formik, Field } from 'formik';
import theme from 'theme';
import { useHistory } from 'react-router-dom';
import {
  toggleDrawer,
  setDrawer,
  drawerSelector,
} from 'slices/drawer';
import { toggleContact, contactSelector } from 'slices/contact';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFlip } from 'swiper';
import { DRAWER_MENU } from 'App/constants';
import Link from 'App/components/Link';
import Translator from 'App/components/Translator';
import MenuIcon from './components/MenuIcon';
import ElevationScroll from './components/ElevationScroll';
import RightMenu from './components/RightMenu';
import HeaderMenu from './components/HeaderMenu';
import ContactUsDialog from './components/ContactUsDialog';
import SearchBar from '../SearchBar';
import DownloadCatalogue from './components/DownloadCatalogue';

const Header = withStyles(() => ({
  blueBar: {
    paddingTop: theme.spacing(1.3),
    background: theme.palette.primary.main,
    height: 40,
    fontSize: 14,
    color: theme.palette.common.white,
  },
  marginBar: {
    [theme.breakpoints.up('md')]: {
      minHeight: 144,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(18),
    },
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.modal + 1,
    },
  },
  navBar: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  logo: {
    height: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(8),
      marginTop: theme.spacing(1.5),
    },
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(-0.5),
    outline: 'none',
  },
  subLogoContainer: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    marginLeft: theme.spacing(1),
    display: 'inline-block',
    '& input': {
      height: theme.spacing(7),
      width: theme.spacing(7),
      background: 'white',
    },
    [theme.breakpoints.down('sm')]: {
      height: 34,
      width: 34,
      '& input': {
        height: 34,
        width: 34,
      },
    },
    outline: 'none',
  },
}))(({ classes, location }) => {
  const history = useHistory();
  const drawer = useSelector(drawerSelector);
  const contact = useSelector(contactSelector);
  const dispatch = useDispatch();
  const toggleContactUsDialog = useCallback(() => dispatch(toggleContact()), [dispatch]);
  const toggleContactUs = useCallback(() => {
    const willDialogOpen = !contact;

    history.replace(willDialogOpen ? '?c' : '');
    toggleContactUsDialog();
  }, [contact, history, toggleContactUsDialog]);
  const handleSelect = (value) => history.push(`/products/${encodeURIComponent(value.id)}`);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (params.has('c')) {
      toggleContactUsDialog();
    }
  }, [toggleContactUsDialog, location.search]);

  return (
    <Formik initialValues={{ search: '' }} onSubmit={() => { }}>
      <>
        <ContactUsDialog open={contact} onClose={toggleContactUs} />
        <ElevationScroll forceElevate={drawer}>
          <AppBar color="inherit" className={classes.appBar}>
            <Hidden smDown>
              <div className={classes.blueBar}>
                <Container>
                  <Grid container spacing={6} justify="flex-end">
                    <Grid item>
                      <Link to="/careers">
                        Careers
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link to="/corporate">
                        Corporate
                      </Link>
                    </Grid>
                    <Grid item>
                      <Box mt={-1}>
                        <Field
                          name="search"
                          component={SearchBar}
                          handleSelect={handleSelect}
                        />
                      </Box>
                    </Grid>
                    <Box mt={2.625}><Translator inline /></Box>
                    <Grid item>
                      <DownloadCatalogue />
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </Hidden>
            <Container disableGutters className={classes.navBar}>
              <Toolbar>
                <Box flexGrow="1" whiteSpace="nowrap">
                  <Link to="/">
                    <input type="image" draggable="false" src={`${process.env.PUBLIC_URL}/images/logo.gif`} alt="INVAS" className={classes.logo} />
                  </Link>
                  <Link to="/">
                    <Swiper
                      loop
                      autoplay
                      effect="flip"
                      allowTouchMove={false}
                      modules={[Autoplay, EffectFlip]}
                      flipEffect={{ slideShadows: false }}
                      className={classes.subLogoContainer}
                    >
                      <SwiperSlide>
                        <input type="image" draggable="false" src={`${process.env.PUBLIC_URL}/images/18-anniv.png`} alt="18th Anniversary" />
                      </SwiperSlide>
                      <SwiperSlide>
                        <input type="image" draggable="false" src={`${process.env.PUBLIC_URL}/images/tsdsi.png`} alt="17th Anniversary" />
                      </SwiperSlide>
                    </Swiper>
                  </Link>
                </Box>
                <Hidden smDown>
                  <HeaderMenu items={DRAWER_MENU} toggleContactUs={toggleContactUs} />
                </Hidden>
                <Hidden mdUp>
                  <Field
                    name="search"
                    component={SearchBar}
                    handleSelect={handleSelect}
                  />
                  <IconButton edge="end" onClick={() => dispatch(toggleDrawer(!drawer))}>
                    <MenuIcon isOpen={drawer} />
                  </IconButton>
                </Hidden>
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
        <Toolbar className={classes.marginBar} />
        <Hidden mdUp>
          <RightMenu
            isOpen={drawer}
            items={DRAWER_MENU}
            toggleContactUs={toggleContactUs}
            handleOpen={() => dispatch(setDrawer(true))}
            handleClose={() => dispatch(setDrawer(false))}
          />
        </Hidden>
      </>
    </Formik>
  );
});

export default Header;
